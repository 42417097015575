import { FlexBox, Text } from '@codecademy/gamut';
import { css } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';

import { getCurrencySymbol } from '~/typings/payment';

import { planData } from './consts';
import { useLocalizedCurrency } from './pricingList';

const StyledPricingFlexBox = styled(FlexBox)(
  css({
    '@media (max-width: 1200px)': {
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
  })
);

export const PlanCardPricing: React.FC<{ plan: (typeof planData)[number] }> = ({
  plan,
}) => {
  const currencyDetails = useLocalizedCurrency();

  switch (plan.id) {
    case 'teams':
      return (
        <StyledPricingFlexBox alignItems="center" gap={4}>
          <FlexBox flexWrap="nowrap" alignItems="center">
            <Text variant="title-lg">
              {getCurrencySymbol(currencyDetails.currency)}
            </Text>
            <Text variant="title-xxl">{currencyDetails.price}</Text>
          </FlexBox>
          <Text variant="p-base" color="text-secondary" textAlign="center">
            {plan.paymentDetail}
          </Text>
        </StyledPricingFlexBox>
      );
    case 'enterprise':
      return <Text screenreader>request a quote</Text>;
    case 'pro':
      return <Text screenreader>learn more about pro</Text>;
    default:
      return null;
  }
};
