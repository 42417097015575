import { Box, Disclosure, FlexBox, Text } from '@codecademy/gamut';
import { CheckCircledIcon } from '@codecademy/gamut-icons';
import styled from '@emotion/styled';

import { planData, tableData } from './consts';
import { FeatureTitle } from './FeatureTitle';
import { PlanCard } from './PlanCard';

const FeatureListItem = styled(Box)`
  &:nth-of-type(even) {
    background: ${({ theme }) => theme.colors['navy-100']};
  }
`;

const PlanListItem = styled(FlexBox)`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors['navy-200']};
    padding-bottom: 16px;
  }
`;

export const PricingTableMobile: React.FC = () => (
  <Box>
    <Text as="h2" variant="title-md" mb={16}>
      Compare plans
    </Text>
    <FlexBox column as="ul" listStyleType="none" gap={16} p={0} mb={16}>
      {planData.map((plan) => (
        <PlanCard key={plan.id} as="li" plan={plan} />
      ))}
    </FlexBox>
    <Disclosure
      heading="Compare features"
      body={
        <Box as="ul" p={0} m={0} mx={-16 as 0} listStyleType="none">
          {tableData.map((feature) => (
            <FeatureListItem key={feature.title} as="li" p={24}>
              <FeatureTitle
                as="h3"
                title={feature.title}
                description={feature.description}
              />
              <Box as="ul" p={0} mt={16} listStyleType="none">
                {planData.map((plan) => (
                  <PlanListItem
                    key={plan.id}
                    as="li"
                    justifyContent="space-between"
                    pt={16}
                  >
                    {plan.title}
                    {feature.plans.includes(plan.id) ? (
                      <CheckCircledIcon
                        size={24}
                        aria-hidden={false}
                        aria-label="yes"
                      />
                    ) : (
                      <Text aria-label="no">-</Text>
                    )}
                  </PlanListItem>
                ))}
              </Box>
            </FeatureListItem>
          ))}
        </Box>
      }
    />
  </Box>
);
