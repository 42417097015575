import { Box, Text } from '@codecademy/gamut';
import { CheckCircledIcon } from '@codecademy/gamut-icons';
import { Background, BackgroundProps, theme } from '@codecademy/gamut-styles';
import styled from '@emotion/styled';
import { useLayoutEffect, useRef, useState } from 'react';

import { planData, tableData } from './consts';
import { FeatureTitle } from './FeatureTitle';
import { renderPlanCardCtas, renderPlanCardDetails } from './PlanCard';
import { PlanCardPricing } from './PlanCardPricing';

const Table = styled(Box)`
  table-layout: fixed;
  height: 1px; // this enables "height: 100%" on table header content without actually affecting table layout
`.withComponent('table');

const Row = styled(Box)`
  &:nth-of-type(odd) {
    background-color: ${theme.colors['navy-100']};
  }
`.withComponent('tr');

// typescript complains about the "scope" prop if we just use a plain <Box as="th" />
const TableTitle = styled(Box)``.withComponent('th');

const TableHeader = styled(Box)`
  vertical-align: top;
  border-left: 1px solid ${theme.colors.navy};
  border-right: 1px solid ${theme.colors.navy};
`.withComponent('th');

const HeaderBackground = styled(Background)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

// these two pseudo elements are used to add top and bottom borders to the sticky
// headers without having to change the border-collapse property on the table
const HeaderRow = styled(Box)`
  &:first-of-type {
    ${HeaderBackground} {
      padding-top: 1.5rem;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -1px;
        width: 100%;
        border-top: 1px solid ${theme.colors.navy};
      }
    }
  }
  &:last-of-type {
    ${HeaderBackground} {
      padding-bottom: 1.5rem;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        border-bottom: 1px solid ${theme.colors.navy};
      }
    }
  }
`.withComponent('tr');

export const PricingTableDesktop: React.FC = () => {
  const titleRowRef = useRef<HTMLTableRowElement>(null);

  // title row is 57 px tall at regular zoom, so we default to this in case the user starts
  // from a mobile view (where the measured height would be 0) and expands their viewport later
  const [titleRowHeight, setTitleRowHeight] = useState(57);

  useLayoutEffect(() => {
    if (titleRowRef.current?.offsetHeight) {
      setTitleRowHeight(titleRowRef.current.offsetHeight);
    }
  }, []);

  return (
    <Table width="100%" position="relative" cellPadding={0}>
      <thead>
        {/* table header is split into multiple <tr>s to enable the collapsing sticky header */}
        <HeaderRow ref={titleRowRef}>
          <TableTitle scope="row" rowSpan={4} verticalAlign="top">
            <Text variant="title-lg">Compare plans</Text>
          </TableTitle>
          {planData.map((plan) => (
            <TableHeader position="sticky" top={theme.elements.headerHeight}>
              <HeaderBackground bg={plan.bg as BackgroundProps['bg']}>
                <Text variant="title-md">{plan.title}</Text>
              </HeaderBackground>
            </TableHeader>
          ))}
        </HeaderRow>

        <HeaderRow>
          {planData.map((plan) => (
            <TableHeader>
              <HeaderBackground bg={plan.bg as BackgroundProps['bg']}>
                {renderPlanCardDetails(plan)}
              </HeaderBackground>
            </TableHeader>
          ))}
        </HeaderRow>

        <HeaderRow>
          {planData.map((plan) => (
            <TableHeader>
              <HeaderBackground bg={plan.bg as BackgroundProps['bg']}>
                <PlanCardPricing plan={plan} />
              </HeaderBackground>
            </TableHeader>
          ))}
        </HeaderRow>

        <HeaderRow>
          {planData.map((plan) => (
            <TableHeader
              position="sticky"
              top={
                // - 1 as the top border of the sticky header merges with the bottom border of the global header
                `calc(var(--elements-headerHeight) + ${titleRowHeight - 1}px)`
              }
              height="100%"
            >
              <HeaderBackground
                bg={plan.bg as BackgroundProps['bg']}
                height="100%"
              >
                {renderPlanCardCtas(plan)}
              </HeaderBackground>
            </TableHeader>
          ))}
        </HeaderRow>
      </thead>

      <Box as="tbody" bg="white" border={1}>
        {tableData.map((row) => (
          <Row key={row.title}>
            <TableHeader scope="row" py={16} px={24}>
              <FeatureTitle title={row.title} description={row.description} />
            </TableHeader>
            {planData.map((plan) => (
              <Box
                as="td"
                key={plan.id}
                p={16}
                textAlign="center"
                borderLeft={1}
              >
                {row.plans.includes(plan.id) ? (
                  <CheckCircledIcon
                    size={24}
                    verticalAlign="middle"
                    aria-hidden={false}
                    aria-label="included"
                  />
                ) : (
                  <>
                    <Text aria-hidden>-</Text>
                    <Text screenreader>not included</Text>
                  </>
                )}
              </Box>
            ))}
          </Row>
        ))}
      </Box>
    </Table>
  );
};
