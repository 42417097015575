import {
  Box,
  Column,
  ContentContainer,
  FillButton,
  FlexBox,
  LayoutGrid,
  StrokeButton,
  Text,
} from '@codecademy/gamut';
import { CheckFilledIcon } from '@codecademy/gamut-icons';
import { Logo, PageHeroProps } from '@mono/brand';
import { FeatureFlag } from '@mono/data/feature-flags';
import * as React from 'react';

import { useFeatureFlag } from '~/libs/FeatureFlags/featureFlags';
import { getTeamsCheckoutPath } from '~/libs/paths';
import { BASE_STATIC_ASSETS_PATH } from '~/libs/staticAssetPaths';
import { trackUserClick } from '~/libs/tracking';

import { LandingPageSectionWrapper } from '../../LandingPageSectionWrapper';
import { PageBusinessFormSingleFeature } from '../PageBusinessFormSingleFeature';
import { trackingPageName } from '../PricingTable/consts';
import { LogoWall } from './LogoWall';

export const BusinessPageHero: React.FC<PageHeroProps> = ({
  showImageOnMobile,
  textLength = 'long',
  ...props
}) => {
  const isNewTeamsLP = useFeatureFlag(FeatureFlag.CYCLE_E_TEAMS_REVAMP);

  const bullets = [
    'Access 600+ courses, tutorials, and projects',
    'Choose in-demand topics like AI, cloud, and more',
    'Progress faster with AI-powered tools',
    'Manage multiple training programs at once',
    'Measure success with reporting and analytics',
  ];

  return isNewTeamsLP ? (
    <>
      <Box position="relative">
        <ContentContainer pr={0}>
          <LayoutGrid>
            <Column size={{ md: 6 }} py={64} pr={{ md: 64 }}>
              <Logo type="teams" height={26} />
              <Text as="h1" variant="title-xl" mt={32}>
                Equip your team with skills for today — and tomorrow
              </Text>
              <Text mt={16} fontSize={18}>
                Meet changing business needs with Codecademy Teams training.
              </Text>
              <FlexBox
                as="ul"
                listStyleType="none"
                p={0}
                mt={16}
                gap={8}
                column
              >
                {bullets.map((bullet) => (
                  <FlexBox
                    key={bullet}
                    as="li"
                    alignItems="flex-start"
                    gap={8}
                    fontSize={18}
                    mt={4}
                  >
                    <CheckFilledIcon flexShrink={0} mt={4} />
                    {bullet}
                  </FlexBox>
                ))}
              </FlexBox>
              <FlexBox
                flexDirection={{ _: 'column', lg: 'row' }}
                alignItems="center"
                maxWidth={{ xs: 'min-content', lg: '100%' }}
                mt={32}
                gap={{ _: 12, lg: 16 }}
              >
                <FillButton
                  href={getTeamsCheckoutPath('directpurchase')}
                  px={64}
                  width={{ _: '100%', lg: 'auto' }}
                  onClick={() =>
                    trackUserClick({
                      page_name: trackingPageName,
                      target: 'business_purchase',
                    })
                  }
                >
                  Purchase today
                </FillButton>
                or
                <StrokeButton
                  href={getTeamsCheckoutPath('trial')}
                  width={{ _: '100%', lg: 'auto' }}
                  onClick={() =>
                    trackUserClick({
                      page_name: trackingPageName,
                      target: 'business_trial',
                    })
                  }
                >
                  Start 14-day free trial
                </StrokeButton>
              </FlexBox>
            </Column>

            <Box
              position={{ xl: 'relative' }}
              gridColumnEnd="span 6"
              display={{ _: 'none', md: 'block' }}
            >
              <Box
                position="absolute"
                right={0}
                top={0}
                width={{ _: '50%', xl: '100%' }}
                height="100%"
                background={`url('${BASE_STATIC_ASSETS_PATH}/business-landing/business-page-hero.png')`}
                backgroundSize="cover"
                backgroundPosition="center bottom"
              />
            </Box>
          </LayoutGrid>
        </ContentContainer>
      </Box>

      <LogoWall />
    </>
  ) : (
    <LandingPageSectionWrapper sectionBackgroundColor="navy">
      <PageBusinessFormSingleFeature
        {...props}
        isPageHeading
        hideImageOnMobile={!showImageOnMobile}
        mediaWidth={6}
      />
    </LandingPageSectionWrapper>
  );
};
