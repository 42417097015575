let loadedStyles = false;
let loadedScript = false;

export const initializeSheerId = () => {
  if (loadedScript && loadedStyles) {
    return Promise.resolve([window.sheerId]);
  }

  const scriptPromise = new Promise((resolve) => {
    const script = document.createElement('script');
    /* SheerID script needs to be loaded in an HTML script module.
      <script type="module">
        import sheerId from "https://cdn.jsdelivr.net/npm/@sheerid/jslib@2/sheerid-install.js"
        var myForm = sheerId.loadInlineIframe(myDiv, myProgramUrl) 
      </script>
    */
    script.type = 'module';
    script.textContent = `import sheerId from "https://cdn.jsdelivr.net/npm/@sheerid/jslib@2/sheerid-install.js"; window.sheerId = sheerId;`;
    document.body.appendChild(script);
    const MAX_RETRIES = 10;
    let retries = 0;
    const sheerIdInterval = setInterval(() => {
      // Check if sheerId has loaded onto the window object every 500ms for a total of 5 seconds.
      if (window.sheerId) {
        clearInterval(sheerIdInterval);
        loadedScript = true;
        resolve(window.sheerId);
      } else if (retries > MAX_RETRIES) {
        clearInterval(sheerIdInterval);
        resolve(null);
        throw new Error('Failed to load SheerID');
      }
      retries += 1;
    }, 500);
  });

  const stylesPromise = new Promise<void>((resolve) => {
    const styles = document.createElement('link');
    styles.onload = () => {
      loadedStyles = true;
      resolve();
    };
    styles.rel = 'stylesheet';
    styles.type = 'text/css';
    styles.href =
      'https://cdn.jsdelivr.net/npm/@sheerid/jslib@2/sheerid-install.css';
    styles.crossOrigin = 'anonymous';
    document.head.appendChild(styles);
  });

  return Promise.all([scriptPromise, stylesPromise]);
};

export const triggerSheerIdModal = async (programId: string) => {
  const [sheerId] = await initializeSheerId();
  const modal = sheerId.loadInModal(
    `https://services.sheerid.com/verify/${programId}/`
  );
  modal.setOptions({ logLevel: 'info' });
};
