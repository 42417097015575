import {
  getPricingPath,
  getTeamsCheckoutPath,
  getTeamsQuote,
} from '~/libs/paths';
import { trackUserClick } from '~/libs/tracking';

export const trackingPageName = 'page_business';

export enum PlanIds {
  pro = 'pro',
  teams = 'teams',
  enterprise = 'enterprise',
}

export const planData = [
  {
    id: PlanIds.pro,
    title: 'Pro',
    description: 'Self-paced learning for self-starters',
    bg: 'paleBlue',
    badge: 'For individuals',
    ctas: [
      {
        href: getPricingPath(),
        label: 'Learn more about Pro',
        variant: 'stroke',
        onClick: () =>
          trackUserClick({
            page_name: trackingPageName,
            target: 'business_pro_pricing',
          }),
      },
    ],
  },
  {
    id: PlanIds.teams,
    title: 'Teams',
    description: 'Technical training and advanced admin tools',
    bg: 'navy',
    badge: 'Recommended for 2+',
    paymentDetail: 'annually, per user',
    ctas: [
      {
        href: getTeamsCheckoutPath('directpurchase'),
        label: 'Purchase today',
        variant: 'fill',
        onClick: () =>
          trackUserClick({
            page_name: trackingPageName,
            target: 'business_purchase',
          }),
      },
      {
        href: getTeamsCheckoutPath('trial'),
        label: 'Start 14-day free trial',
        variant: 'stroke',
        onClick: () =>
          trackUserClick({
            page_name: trackingPageName,
            target: 'business_trial',
          }),
      },
    ],
  },
  {
    id: PlanIds.enterprise,
    title: 'Enterprise',
    description: 'Customizable training for your organization',
    bg: 'paleBlue',
    badge: 'Recommended for 50+',
    ctas: [
      {
        href: getTeamsQuote(),
        label: 'Request a demo',
        variant: 'stroke',
        onClick: () =>
          trackUserClick({
            page_name: trackingPageName,
            target: 'business_enterprise',
          }),
      },
    ],
  },
];

export const tableData = [
  {
    title: 'Full Codecademy catalog',
    plans: [PlanIds.pro, PlanIds.teams, PlanIds.enterprise],
    description:
      'Unlimited access to 600+ courses and paths aligned with leading industry skills.',
  },
  {
    title: 'Structured learning paths',
    plans: [PlanIds.pro, PlanIds.teams, PlanIds.enterprise],
    description:
      'Reach business and professional goals with guided skill paths and career paths.',
  },
  {
    title: 'Interactive lessons',
    plans: [PlanIds.pro, PlanIds.teams, PlanIds.enterprise],
    description: 'Courses and paths help teams build hands-on experience.',
  },
  {
    title: 'Real-world projects',
    plans: [PlanIds.pro, PlanIds.teams, PlanIds.enterprise],
    description:
      'Access our library of practice and portfolio projects to apply new skills.',
  },
  {
    title: 'Workspaces',
    plans: [PlanIds.pro, PlanIds.teams, PlanIds.enterprise],
    description:
      'Learn, practice, and build projects together in our collaborative coding environment.',
  },
  {
    title: 'Certificates of completion',
    plans: [PlanIds.pro, PlanIds.teams, PlanIds.enterprise],
    description:
      'Team members can earn certificates for each course or path they finish.',
  },
  {
    title: 'AI assistance',
    plans: [PlanIds.pro, PlanIds.teams, PlanIds.enterprise],
    description:
      'Get help working through coding problems, check solution code, and see explanations of errors and concepts without leaving the platform.',
  },
  {
    title: 'Assessments',
    plans: [PlanIds.pro, PlanIds.teams, PlanIds.enterprise],
    description:
      'Test skills as your team builds them to see what’s going well and what needs work.',
  },
  {
    title: 'Mobile learning',
    plans: [PlanIds.pro, PlanIds.teams, PlanIds.enterprise],
    description:
      'Learn on the go from your mobile web browser, and practice anywhere with our Codecademy Go companion app.',
  },
  {
    title: 'Unlimited seat transfer',
    plans: [PlanIds.teams, PlanIds.enterprise],
    description: 'Swap seats as team needs change.',
  },
  {
    title: 'Admin dashboard',
    plans: [PlanIds.teams, PlanIds.enterprise],
    description:
      'Manage assignments, seat transfer, billing, and reporting all in one place.',
  },
  {
    title: 'Progress and usage reporting',
    plans: [PlanIds.teams, PlanIds.enterprise],
    description:
      'Track progress and get insights on how effectively your team is learning.',
  },
  {
    title: 'Customizable learner groups',
    plans: [PlanIds.teams, PlanIds.enterprise],
    description:
      'Manage multiple training programs by assigning team members to different groups.',
  },
  {
    title: 'Content assignment',
    plans: [PlanIds.teams, PlanIds.enterprise],
    description:
      'Choose and assign the content that fits your team’s unique needs.',
  },
  {
    title: 'Reporting API',
    plans: [PlanIds.enterprise],
    description:
      'Integrate new data into your main dashboards to easily track your team’s progress.',
  },
  {
    title: 'LMS, LXP, HCM, and more integrations',
    plans: [PlanIds.enterprise],
    description:
      '[See the full list on our parent company’s website.](https://www.skillsoft.com/integration-partners)',
  },
  {
    title: 'Customizable content extensions and add-ons',
    plans: [PlanIds.enterprise],
    description: 'Contact our sales team for full details.',
  },
];
